<template>
    <div class="user-dashboard-index-page">
        <div class="user-dashboard-index-page__header">
            <div class="dashboard-header__info">
                <picture class="dashboard-header__picture">
                    <img
                        v-if="userProfileData.user.image && userProfileData.user.image.url"
                        :src="userProfileData.user.image.url"
                        class="user-image"
                        alt="image"
                    />
                    <img v-else :src="$assets.gray.userHeader" class="user-default-image" alt="default" />
                </picture>
                <div class="dashboard-header__details">
                    <div class="dashboard-header__name-block">
                        <p class="dashboard-header__name">{{ userProfileData.user.username }}</p>
                        <MiscBadge v-if="userProfileData.user.verified" :full-badge="false" />
                    </div>
                    <p class="dashboard-header__timestamp">
                        {{ $lang.pages.dashboardIndexMain.member_since }} <span>{{ memberSince }}</span>
                    </p>
                    <div class="dashboard-header__rank">
                        <!-- <p class="dashboard-header__rank-followers"><span>32</span>Seguidores</p> -->
                        <div class="dashboard-header__rank-user">
                            <div class="dashboard-header__rank-user-icon">
                                <div><i class="fas fa-trophy" /></div>
                                <span class="block lg:hidden">#{{ userProfileData.ranking }}</span>
                            </div>
                            <p class="dashboard-header__rank-user-text">
                                {{ $lang.pages.dashboardIndexMain.ranking }}
                                <span class="hidden lg:inline-block">#{{ userProfileData.ranking }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    v-if="Route.path !== `/${$lang.routes.dashboard}/${$lang.routes.settings}`"
                    class="dashboard-header__settings"
                >
                    <NuxtLink
                        no-prefetch
                        :to="`/${$lang.routes.dashboard}/${$lang.routes.settings}`"
                        class="dashboard-header__settings-btn"
                    >
                        <i class="fas fa-cog fa-lg lg:fa-sm"></i>
                        <p>{{ $lang.pages.dashboardIndexMain.settings }}</p>
                    </NuxtLink>
                </div>
            </div>
        </div>
        <div class="user-dashboard-index-page__content">
            <main
                class="user-dashboard-index-page__main"
                :class="
                    $route.path !== `/${$lang.routes.dashboard}/${$lang.routes.settings}`
                        ? 'order-2 lg:order-1'
                        : 'order-1 lg:order-2'
                "
            >
                <NuxtPage />
            </main>
            <template v-if="$route.path !== `/${$lang.routes.dashboard}/${$lang.routes.settings}`">
                <aside class="user-dashboard-index-page__aside-coins">
                    <h2 class="user-dashboard-index-page__aside-coins-title">
                        {{ $lang.pages.dashboardIndexMain.rewards }}
                    </h2>
                    <section class="right-content">
                        <ul class="right-content__list">
                            <li v-for="(coin, index) in coins" :key="index" class="right-content__item">
                                <DashCardCoin :info="coin" :is-private="true" />
                            </li>
                        </ul>
                    </section>
                </aside>
                <aside class="user-dashboard-index-page__aside-statistics">
                    <div class="user-dashboard-index-page__aside-statistics-wrapper">
                        <h2 class="user-dashboard-index-page__aside-statistics-title">Estadísticas</h2>
                        <section v-for="(section, sIndex) in statistics" :key="sIndex" class="left-section">
                            <ul class="left-section__list">
                                <li
                                    v-for="(item, iIndex) in section.items"
                                    :key="iIndex"
                                    class="left-section__item"
                                >
                                    <div class="left-section__item-count">
                                        <img :src="item.icon" alt="icon" />
                                        <span>{{ item.count }}</span>
                                    </div>
                                    <span class="left-section__item-description">{{ item.text }}</span>
                                </li>
                            </ul>
                        </section>
                    </div>
                </aside>
                <aside class="user-dashboard-index-page__aside-verified">
                    <div class="user-dashboard-index-page__aside-verified-wrapper">
                        <h2 class="user-dashboard-index-page__aside-verified-title">
                            <img :src="$assets.primary.verified" alt="verificado" /> Usuario verificado
                        </h2>
                        <div class="user-dashboard-index-page__aside-verified-bar-wrapper">
                            <div class="user-dashboard-index-page__aside-verified-bar">
                                <div
                                    class="user-dashboard-index-page__aside-verified-bar-internal"
                                    :style="`width: ${userProfileData.user.verified ? 100 : userPercent}%`"
                                />
                            </div>
                            <span class="user-dashboard-index-page__aside-verified-bar-text">100%</span>
                        </div>
                        <div class="user-dashboard-index-page__aside-verified-description">
                            <i class="fas fa-info-circle text-[#94949B]" />
                            {{
                                userPercent === 100 || userProfileData.user.verified
                                    ? 'Tu usuario ya ha sido verificado'
                                    : `Es un usuario que tiene la insignia de “Verificado” y lo consigue tras 25 ofertas/cupones aprobados y 50 comentarios aprobados. Se le debe enviar un correo electrónico para informarle y además se le informará por la mensajería y en notificaciones.`
                            }}
                        </div>
                        <template v-if="userPercent < 100 && !userProfileData.user.verified">
                            <p class="user-dashboard-index-page__aside-verified-subtitle">Cupones/ofertas</p>
                            <div class="user-dashboard-index-page__aside-verified-minibar-wrapper">
                                <div class="user-dashboard-index-page__aside-verified-minibar">
                                    <div
                                        class="user-dashboard-index-page__aside-verified-minibar-internal"
                                        :style="`width: ${discountsPercent}%`"
                                    >
                                        <span class="user-dashboard-index-page__aside-verified-flotant">{{
                                            discountsTotal
                                        }}</span>
                                    </div>
                                </div>
                                <span class="user-dashboard-index-page__aside-verified-bar-text">25</span>
                            </div>
                            <p class="user-dashboard-index-page__aside-verified-subtitle">Comentarios</p>
                            <div class="user-dashboard-index-page__aside-verified-minibar-wrapper">
                                <div class="user-dashboard-index-page__aside-verified-minibar">
                                    <div
                                        class="user-dashboard-index-page__aside-verified-minibar-internal"
                                        :style="`width: ${commentsPercent}%`"
                                    >
                                        <span class="user-dashboard-index-page__aside-verified-flotant">{{
                                            commentsTotal
                                        }}</span>
                                    </div>
                                </div>
                                <span class="user-dashboard-index-page__aside-verified-bar-text">50</span>
                            </div>
                        </template>
                    </div>
                </aside>
            </template>
            <aside v-else class="user-dashboard-index-page__aside-settings-menu">
                <ul class="aside-settings-menu-wrapper">
                    <li
                        class="aside-settings-menu-wrapper__item"
                        :class="
                            $route.fullPath === `/${$lang.routes.dashboard}/${$lang.routes.settings}`
                                ? 'aside-settings-menu-wrapper__item-active'
                                : 'text-[#404040]'
                        "
                    >
                        <NuxtLink no-prefetch :to="`/${$lang.routes.dashboard}/${$lang.routes.settings}`">
                            <div><i class="fas fa-user-alt"></i></div>
                            {{ $lang.pages.dashboardIndexMain.account }}</NuxtLink
                        >
                    </li>
                    <li
                        class="aside-settings-menu-wrapper__item"
                        :class="
                            $route.fullPath ===
                            `/${$lang.routes.dashboard}/${$lang.routes.settings}?tab=notificaciones`
                                ? 'aside-settings-menu-wrapper__item-active'
                                : 'text-[#404040]'
                        "
                    >
                        <NuxtLink
                            no-prefetch
                            :to="`/${$lang.routes.dashboard}/${$lang.routes.settings}?tab=notificaciones`"
                        >
                            <div><i class="fas fa-bell"></i></div>
                            {{ $lang.pages.dashboardIndexMain.notifications }}</NuxtLink
                        >
                    </li>
                    <li
                        class="aside-settings-menu-wrapper__item"
                        :class="
                            $route.fullPath ===
                            `/${$lang.routes.dashboard}/${$lang.routes.settings}?tab=conexion-social`
                                ? 'aside-settings-menu-wrapper__item-active'
                                : 'text-[#404040]'
                        "
                    >
                        <NuxtLink
                            no-prefetch
                            :to="`/${$lang.routes.dashboard}/${$lang.routes.settings}?tab=conexion-social`"
                        >
                            <div><i class="fas fa-chart-network"></i></div>
                            {{ $lang.pages.dashboardIndexMain.social_connection }}</NuxtLink
                        >
                    </li>
                    <li class="aside-settings-menu-wrapper__item">
                        <button type="button" @click="() => AuthStore.signOut(Route.name as string)">
                            <div><i class="fas fa-sign-out-alt"></i></div>
                            {{ $lang.pages.dashboardIndexMain.close_session }}
                        </button>
                    </li>
                </ul>
            </aside>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { format, parseISO } from 'date-fns'
import { es as locale } from 'date-fns/locale'
import { useAuthStore } from '~/store/auth'
import { useFallbackStore } from '~/store/fallback'

import type { Api } from '~~/global'

definePageMeta({
    middleware: ['auth-required'],
})

const AuthStore = useAuthStore()
const FallbackStore = useFallbackStore()

const { $lang, $assets } = useNuxtApp()
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const { data: responseData, error } = await useAsyncData('user-profile-data', () => {
    return $fetch<Api.Responses.Pages.UserProfile>(
        endpoints.pages.user.path.replace('_ID_', String(AuthStore.UserData?.id || '')),
        {
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
            method: 'GET',
        },
    ).catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: 404,
        message: responseData.value?.feedback || error.value?.message || 'Error data',
    })
}

FallbackStore.setUserProfileFallback(responseData.value)

const userProfileData = ref(responseData.value.data)

const coins = [
    {
        title: $lang.pages.dashboardIndexMain.coins_available,
        coins: userProfileData.value.statics.third_section.coins_available,
        icon: $assets.yellow.coin,
    },
    {
        title: $lang.pages.dashboardIndexMain.coins_total,
        coins: userProfileData.value.statics.third_section.collected_coins,
        icon: $assets.yellow.coinStack,
    },
    {
        title: $lang.pages.dashboardIndexMain.awards,
        coins: userProfileData.value.statics.third_section.awards_count,
        icon: $assets.yellow.coinPrize,
    },
]
const statistics = [
    {
        items: [
            {
                icon: $assets.primary.dashOffersAndCoupons,
                count:
                    userProfileData.value.statics.first_section.published_offers_count +
                    userProfileData.value.statics.first_section.published_coupons_count,
                text: $lang.pages.dashboardIndexMain.discounts,
            },
            {
                icon: $assets.primary.dashComments,
                count: userProfileData.value.statics.first_section.published_comments_count,
                text: $lang.pages.dashboardIndexMain.comments,
            },

            {
                icon: $assets.primary.dashChat,
                count: userProfileData.value.statics.first_section.published_forums_count,
                text: $lang.pages.dashboardIndexMain.chats,
            },
            {
                icon: $assets.primary.dashLike,
                count: userProfileData.value.statics.second_section.likes_in_comments_published_count,
                text: $lang.pages.dashboardIndexMain.likes_sended,
            },
        ],
    },
]
const accountState = reactive({
    follow: false,
    notifications: false,
})

const memberSince = computed(() => {
    return format(parseISO(userProfileData.value.user.created_at || ''), "d 'de' MMMM 'del' yyyy", { locale })
})
const discountsTotal = computed(() => {
    const total =
        userProfileData.value.statics.first_section.published_offers_count +
        userProfileData.value.statics.first_section.published_coupons_count
    return total >= 100 ? 100 : total
})
const commentsTotal = computed(() => {
    const total = userProfileData.value.statics.first_section.published_comments_count
    return total >= 100 ? 100 : total
})
const discountsPercent = computed(() => {
    return ((discountsTotal.value >= 25 ? 25 : discountsTotal.value) / 25) * 100
})
const commentsPercent = computed(() => {
    return ((commentsTotal.value >= 50 ? 50 : commentsTotal.value) / 50) * 100
})
const userPercent = computed(() => {
    return discountsPercent.value * 0.5 + commentsPercent.value * 0.5
})

const title = $lang.pages.dashboardIndexMain.title_seo
const description = $lang.pages.dashboardIndexMain.description_seo

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.user-dashboard-index-page {
    @apply mx-auto w-full max-w-6xl;
    &__header {
        @apply relative;
        .dashboard-header {
            &__background {
                @apply absolute right-0 top-0 z-0 h-full w-auto;
            }
            &__info {
                @apply container relative z-10 flex flex-col bg-white px-0 pb-5 pt-4 lg:flex-row lg:gap-x-3 lg:bg-inherit;
            }
            &__picture {
                @apply mx-auto block h-20 w-20 flex-none overflow-hidden rounded-full bg-gray-100 lg:mx-0;
                .user-image {
                    @apply h-full w-full object-cover;
                }
                .user-default-image {
                    @apply h-full w-full object-contain p-4;
                }
            }
            &__details {
                @apply flex-grow items-center text-gray-800 lg:space-y-1;
            }
            &__name-block {
                @apply mt-3 flex w-full items-center justify-center lg:mt-0 lg:w-max;
            }
            &__name {
                @apply text-center text-xl font-semibold lg:text-left;
            }
            &__buttons {
                @apply ml-4 flex max-w-max flex-grow flex-row-reverse items-end lg:ml-auto lg:flex-row;
            }
            &__notification-button {
                @apply ml-2 block h-7 w-7 rounded-full bg-black bg-opacity-40 pl-1.5 lg:ml-0 lg:h-8 lg:w-8;
                img {
                    @apply block w-4 lg:w-5;
                }
            }
            &__follow-button {
                @apply rounded-full bg-blue-500 px-3 py-1 text-sm text-white lg:ml-5 lg:rounded-lg lg:px-8 lg:py-1.5;
            }
            &__timestamp {
                @apply w-full text-center text-xs text-[#94949B] lg:w-max lg:text-left;
                span {
                    @apply font-semibold;
                }
            }

            &__rank {
                @apply mt-4 flex items-center justify-center gap-2 text-sm lg:mt-2 lg:justify-start;
                &-followers {
                    @apply flex w-36 flex-col gap-1 rounded p-2 text-center font-medium text-[#6B6870] shadow-rounded lg:w-fit lg:flex-row lg:rounded-none lg:p-0 lg:text-left lg:shadow-none;
                    span {
                        @apply text-base font-semibold lg:text-sm;
                    }
                }
                &-user {
                    @apply flex w-36 flex-col items-center gap-1 rounded p-2 text-center text-site-primary shadow-rounded lg:w-fit lg:flex-row lg:rounded-none lg:p-0 lg:text-left lg:shadow-none;
                    &-icon {
                        @apply flex gap-1 text-center text-base font-semibold lg:block lg:text-sm;
                    }
                    &-text {
                        @apply flex flex-nowrap gap-1 whitespace-nowrap font-medium;
                    }
                }
            }
            &__settings {
                @apply absolute right-0 top-2 lg:static lg:justify-end;
                &-btn {
                    @apply flex items-center justify-center gap-2 p-2 px-4 text-[#6B6870] lg:rounded-xl lg:border lg:border-site-primary lg:text-site-primary lg:transition lg:duration-200 lg:hover:bg-site-primary lg:hover:text-white;
                    p {
                        @apply hidden lg:block;
                    }
                }
            }

            &__followers,
            &__user-rank {
                @apply whitespace-nowrap leading-6;
            }
        }
    }
    &__content {
        @apply container grid grid-cols-12 items-start justify-start bg-white px-0 lg:gap-3 lg:bg-inherit lg:pb-0;
    }
    &__main {
        @apply col-span-12 flex flex-col self-stretch overflow-hidden bg-white xs:rounded-xl lg:col-span-9 lg:row-span-4 lg:mb-3;
    }
    &__aside-coins {
        @apply order-1 col-span-12 m-2 mx-4 overflow-hidden rounded-lg bg-white shadow-rounded lg:order-2 lg:col-span-3 lg:col-start-10 lg:row-start-1 lg:m-0 lg:mx-0 lg:rounded-xl lg:shadow-none;
        &-title {
            @apply border-b border-gray-200 border-opacity-50 px-3 py-1.5 text-sm font-medium;
        }
        .right-content {
            @apply pb-2;
            &__item {
                @apply px-2 pt-2;
            }
        }
    }
    &__aside-statistics {
        @apply order-3 col-span-12 bg-site-background pt-2 lg:col-span-3 lg:col-start-10 lg:row-start-2 lg:pt-0;
        &-wrapper {
            @apply m-2 rounded-lg bg-white shadow-rounded xs:m-0 xs:rounded-xl lg:shadow-none;
        }
        &-title {
            @apply border-b border-gray-200 border-opacity-50 px-3 py-1.5 text-sm font-medium;
        }
        .left-section {
            @apply px-3 py-2;
            &__list {
                @apply space-y-3;
            }
            &__item {
                @apply flex space-x-2 align-baseline text-xs text-[#404040];
                &-count {
                    @apply flex items-center gap-2;
                    img {
                        @apply h-3 w-3;
                    }
                    span {
                        @apply w-6 font-semibold;
                    }
                }
                /* &-description {
                    @apply align-baseline;
                } */
            }
        }
    }
    &__aside-verified {
        @apply order-4 col-span-12 bg-site-background pb-2 xs:pt-2 lg:col-span-3 lg:col-start-10 lg:row-start-3 lg:pb-0 lg:pt-0;
        &-wrapper {
            @apply m-2 rounded-lg bg-white shadow-rounded xs:m-0 xs:rounded-xl lg:shadow-none;
        }
        &-title {
            @apply flex items-center gap-1 border-opacity-50 px-3 py-1.5 text-sm font-medium;
        }
        &-bar-wrapper {
            @apply flex h-full w-full items-center justify-center gap-1 px-3 pb-2 pt-1;
        }
        &-minibar-wrapper {
            @apply flex h-full w-full items-center justify-center gap-1 px-3 pb-3;
        }
        &-bar {
            @apply h-4 w-full rounded-xl bg-[#F2F0F4];
        }
        &-minibar {
            @apply h-max w-full rounded-xl bg-[#F2F0F4];
            &:hover {
                @apply cursor-pointer;
                .user-dashboard-index-page__aside-verified-flotant {
                    @apply block;
                }
            }
        }
        &-bar-internal {
            @apply h-full rounded-xl bg-site-primary py-1 text-center leading-none text-white;
        }
        &-minibar-internal {
            @apply relative h-2 rounded-xl bg-site-primary text-center leading-none text-white;
        }
        &-bar-text {
            @apply text-xs font-semibold text-[#BBB7BE];
        }
        &-description {
            @apply px-3 py-1 text-xs text-[#6B6870];
        }
        &-subtitle {
            @apply mt-3 px-3 text-xs text-[#404040];
        }
        &-flotant {
            @apply absolute bottom-1/2 right-0 hidden h-5 w-8 -translate-y-2 translate-x-1/2 transform rounded bg-white text-center shadow-lg shadow-slate-900;
            @apply text-xs font-medium leading-5 text-[#404040] shadow-rounded;
            @apply ml-0 !important;

            &::after {
                @apply absolute -bottom-2 left-1/2 block h-2.5 w-2 -translate-x-1/2 transform bg-[url('/assets/svg/stats-marker.svg')] bg-cover bg-top bg-no-repeat;
                content: '';
            }
        }
    }
    &__aside-settings-menu {
        @apply col-span-12 m-2 mx-4 hidden overflow-hidden rounded-lg bg-white shadow-rounded lg:order-1 lg:col-span-3 lg:col-start-1 lg:row-start-1 lg:m-0 lg:mx-0 lg:block lg:rounded-xl lg:shadow-none;
        .aside-settings-menu-wrapper {
            @apply space-y-3 p-3 py-4 font-medium;
            &__item {
                @apply transition duration-200 hover:brightness-125;
                &-active {
                    @apply font-semibold text-site-primary;
                }
                a,
                button {
                    @apply flex items-center gap-1.5;
                }
            }
        }
    }
}
</style>
